import { createApp } from "vue";
import Vue3linkify from "vue-3-linkify";

import I18n from "@/app/javascript/plugins/i18n";

import store from "./guestStore";

import Guest from "./pages/Guest.vue";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("guest-app");
  const props = JSON.parse(node.dataset.props);
  const translations = JSON.parse(node.dataset.translations);
  const storeData = JSON.parse(node.dataset.store);

  const app = createApp(Guest, props);

  app.use(store);
  app.use(I18n, translations);
  app.use(Vue3linkify);

  store.commit("setGuest", storeData.guest);
  store.commit("setPlusOnes", storeData.plusOnes);
  store.commit("setEvent", storeData.event);

  app.mount(node);
});
