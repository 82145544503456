import api from "@/app/javascript/api";
import {
  plannerGuestApiPageGuestsPath,
  plannerGuestApiPageEmailsPath,
  plannerGuestApiPageAnswerPath
} from "@/app/javascript/routes";

export default {
  async index(pageId, params) {
    return api.get(plannerGuestApiPageGuestsPath(pageId), {
      params
    });
  },
  async email(pageId, params) {
    return api.post(plannerGuestApiPageEmailsPath(pageId), params);
  },
  async respond(pageId, params) {
    return api.put(plannerGuestApiPageAnswerPath(pageId), params);
  }
};
