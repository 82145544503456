<template>
  <div>
    <h3
      class="typeset-4 question-label mb-4"
      data-element="questionLabel"
      :style="computedStyle(theme, 'questionLabel')">
      {{ question.question }}
      <span
        v-if="question.required"
        class="text-red">
        *
      </span>
    </h3>
    <div v-if="guest">
      <text-input
        v-model="guest.answers_data[question.id]"
        :input-id="`text-${question.id}-${guest.id}`"
        :required="question.required"
        :errors="formErrors[`guest-${guest.id}-${question.id}`]"
        class="mb-4"
        :theme="page.design.theme" />
    </div>

    <div v-else>
      <text-input
        :input-id="`text-${question.id}`"
        data-element="textInput"
        :required="question.required"
        class="mb-4"
        :theme="page.design.theme" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextInput from "@/app/javascript/components/shared/TextInput.vue";
import computedStyle from "../../../mixins/computedStyle";

export default {
  name: "TextQuestion",
  components: {
    TextInput
  },
  mixins: [computedStyle],
  props: {
    question: {
      type: Object,
      required: true
    },
    guest: {
      type: Object,
      default: null
    },
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      formErrors: state => state.formErrors
    }),
    theme() {
      return this.page.design.theme;
    }
  }
};
</script>
