import { createStore } from "vuex";

import flashMessages from "@/app/javascript/store/modules/flashMessages";

export default createStore({
  modules: {
    flashMessages
  },
  state: {
    guest: null,
    plusOnes: null,
    event: null,
    formErrors: [],
    loading: false,
    finished: false
  },
  getters: {},
  mutations: {
    setGuest(state, guest) {
      if (guest) {
        guest.answers_data = {};
        guest.rsvp = "unknown";
      }
      state.guest = guest;
    },
    setPlusOnes(state, plusOnes) {
      if (plusOnes) {
        plusOnes.forEach(question => {
          question.answers_data = {};
          question.rsvp = "unknown";
        });
      }
      state.plusOnes = plusOnes;
    },
    setEvent(state, event) {
      state.event = event;
    },
    setFormErrors(state, formErrors) {
      state.formErrors = formErrors;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setFinished(state, finished) {
      state.finished = finished;
    }
  },
  actions: {}
});
