<template>
  <component
    :is="globalTemplate"
    :page="pageToUse">
    <overlay v-if="loading" />
    <flash-messages />
    <fade-transition
      mode="out-in"
      appear>
      <response-confirmation
        v-if="finished"
        key="response-confirmation" />
      <guest-id
        v-else-if="!isPreview && !guest"
        key="guest-id"
        :page="pageToUse" />
      <component
        :is="pageTemplate"
        v-else
        key="page"
        :page="pageToUse">
        <page
          :page="pageToUse"
          :preview="isPreview" />
      </component>
    </fade-transition>
    <template #highlighter>
      <div
        v-for="(element, index) in highlightedElements"
        :key="index"
        class="highlighter"
        :style="highlightedElementStyles(element)"></div>
    </template>
  </component>
</template>

<script>
import { clone } from "lodash-es";
import { mapState } from "vuex";
import GlobalTemplate0 from "@/components/planner/app/javascript/components/page/templates/GlobalTemplate0.vue";
import GlobalTemplate1 from "@/components/planner/app/javascript/components/page/templates/GlobalTemplate1.vue";
import GlobalTemplate2 from "@/components/planner/app/javascript/components/page/templates/GlobalTemplate2.vue";
import GlobalTemplate3 from "@/components/planner/app/javascript/components/page/templates/GlobalTemplate3.vue";
import GlobalTemplate4 from "@/components/planner/app/javascript/components/page/templates/GlobalTemplate4.vue";
import GlobalTemplate5 from "@/components/planner/app/javascript/components/page/templates/GlobalTemplate5.vue";
import PageTemplate1 from "@/components/planner/app/javascript/components/page/templates/PageTemplate1.vue";
import PageTemplate2 from "@/components/planner/app/javascript/components/page/templates/PageTemplate2.vue";
import PageTemplate3 from "@/components/planner/app/javascript/components/page/templates/PageTemplate3.vue";
import PageTemplate4 from "@/components/planner/app/javascript/components/page/templates/PageTemplate4.vue";
import PageTemplate5 from "@/components/planner/app/javascript/components/page/templates/PageTemplate5.vue";
import FlashMessages from "@/app/javascript/components/shared/FlashMessages.vue";
import Overlay from "@/app/javascript/components/shared/Overlay.vue";
import FadeTransition from "@/app/javascript/components/transitions/FadeTransition.vue";
import ResponseConfirmation from "../components/page/ResponseConfirmation.vue";
import Page from "../components/page/Page.vue";
import GuestId from "../components/page/GuestId.vue";

export default {
  name: "Guest",
  components: {
    GlobalTemplate0,
    GlobalTemplate1,
    GlobalTemplate2,
    GlobalTemplate3,
    GlobalTemplate4,
    GlobalTemplate5,
    PageTemplate1,
    PageTemplate2,
    PageTemplate3,
    PageTemplate4,
    PageTemplate5,
    FlashMessages,
    Overlay,
    ResponseConfirmation,
    Page,
    GuestId,
    FadeTransition
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    share: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      dataPage: null,
      originalDataPage: null,
      highlightedElements: []
    };
  },
  computed: {
    ...mapState({
      guest: state => state.guest,
      loading: state => state.loading,
      finished: state => state.finished
    }),
    globalTemplate() {
      return `global-template-${this.pageToUse.design.template_id}`;
    },
    pageTemplate() {
      return `page-template-${this.pageToUse.design.template_id}`;
    },
    isPreview() {
      const pathSplit = window.location.pathname.split("/");
      return pathSplit[pathSplit.length - 1] === "preview";
    },
    pageToUse() {
      return this.dataPage || this.page;
    },
    highlightedElementStyles() {
      return element => {
        return {
          top: `${element.getBoundingClientRect().top}px`,
          left: `${element.getBoundingClientRect().left}px`,
          width: `${element.getBoundingClientRect().width}px`,
          height: `${element.getBoundingClientRect().height}px`
        };
      };
    }
  },
  mounted() {
    this.dataPage = clone(this.page);
    window.addEventListener("message", this.receiveMessage, false);
    this.originalDataPage = clone(this.page);
  },
  methods: {
    receiveMessage(event) {
      if (!event.data.payload) return;

      const data = JSON.parse(event.data.payload);
      if (event.data.type === "papier") {
        this.dataPage = data;
      } else if (event.data.type === "papierThemeDesigner") {
        this.dataPage.design.theme = data;
      } else if (event.data.type === "papierTemplateSwitcher") {
        this.dataPage.design.template_id = data.template;
      } else if (event.data.type === "papierPageTypeSwitcher") {
        if (!data) {
          this.dataPage = clone(this.originalDataPage);
        } else {
          this.dataPage = { ...this.dataPage, ...data };
        }
      } else if (event.data.type === "papierThemeHighlighter") {
        this.highlightedElements = [];
        if (data.element) {
          this.highlightedElements = document.querySelectorAll(
            `[data-element="${data.element}"]`
          );
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.highlighter {
  position: fixed;
  box-shadow: 0 0 0 1px rgba(0, 173, 255, 1);
  background-color: rgba(0, 173, 255, 0.3);
}
</style>
