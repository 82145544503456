<template>
  <div
    class="main-page-body relative"
    data-element="mainPageBody"
    :style="page.design.theme.mainPageBody">
    <theme-image
      v-if="imageExists(theme, 'top_left_corner')"
      v-bind="themeImageProps('top_left_corner')" />
    <theme-image
      v-if="imageExists(theme, 'top_right_corner')"
      v-bind="themeImageProps('top_right_corner')" />
    <theme-image
      v-if="imageExists(theme, 'bottom_left_corner')"
      v-bind="themeImageProps('bottom_left_corner')" />
    <theme-image
      v-if="imageExists(theme, 'bottom_right_corner')"
      v-bind="themeImageProps('bottom_right_corner')" />
    <div
      class="main-page-content mx-auto p-5 sm:p-7"
      data-element="mainPageContent"
      :style="page.design.theme.mainPageContent">
      <div class="text-center">
        <h1
          v-if="page.title"
          v-linkify
          data-element="title"
          class="title mb-4 text"
          :style="computedStyle(theme, 'title')"
          v-html="page.title"></h1>
        <div
          v-if="page.text_area_1"
          v-linkify
          class="text-area-1 mb-4 text"
          data-element="text_area_1"
          :style="computedStyle(theme, 'text_area_1')"
          v-html="page.text_area_1"></div>
        <div
          v-if="
            (page.first_name || page.last_name) &&
            (page.partner_first_name || page.partner_last_name)
          "
          class="names mb-4 text">
          <div
            class="name flex flex-wrap justify-center gap-x-3 md:gap-x-4"
            :style="theme.names">
            <span
              v-linkify
              data-cs-mask
              class="text first-name"
              data-element="firstName"
              :style="computedStyle(theme, 'firstName')"
              v-html="page.first_name"></span>
            <span
              v-linkify
              data-cs-mask
              class="text last-name"
              data-element="lastName"
              :style="computedStyle(theme, 'lastName')"
              v-html="page.last_name"></span>
          </div>
          <div
            :style="computedStyle(theme, 'and')"
            data-element="and"
            class="and">
            {{ theme.and.content }}
          </div>
          <div
            class="name flex flex-wrap justify-center gap-x-3 md:gap-x-4"
            :style="computedStyle(theme, 'names')">
            <span
              v-linkify
              data-cs-mask
              class="text first-name partner-first-name"
              data-element="firstName"
              :style="computedStyle(theme, 'firstName')"
              v-html="page.partner_first_name"></span>
            <span
              v-linkify
              data-cs-mask
              class="text last-name partner-last-name"
              data-element="lastName"
              :style="computedStyle(theme, 'lastName')"
              v-html="page.partner_last_name"></span>
          </div>
        </div>
        <div
          v-if="page.text_area_2"
          v-linkify
          class="text-area-2 mb-4 text"
          data-element="text_area_2"
          :style="computedStyle(theme, 'text_area_2')"
          v-html="page.text_area_2"></div>
        <div
          v-if="page.date"
          class="date mb-4"
          data-element="date"
          :style="computedStyle(theme, 'date')">
          {{ dateString }}
        </div>
        <div
          v-if="page.text_area_3"
          v-linkify
          data-element="text_area_3"
          :style="computedStyle(theme, 'text_area_3')"
          class="text-area-3 whitespace-pre-wrap text"
          v-html="page.text_area_3"></div>
      </div>
      <hr
        v-if="hasVisiblePageQuestions"
        class="hr my-7"
        data-element="hr"
        :style="computedStyle(theme, 'hr')" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import computedStyle from "../../../mixins/computedStyle";
import imageExists from "../../../mixins/imageExists";
import dates from "./dates";

import ThemeImage from "../ThemeImage.vue";

/* eslint no-underscore-dangle: ["error", { "allow": ["_destroy"] }] */
export default {
  name: "PageTemplate4",
  components: {
    ThemeImage
  },
  mixins: [computedStyle, imageExists, dates],
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateOptions: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    };
  },
  computed: {
    dateString() {
      return this.date.toLocaleDateString(undefined, this.dateOptions);
    },
    theme() {
      return this.page.design.theme;
    },
    themeImageProps() {
      return position => {
        return {
          position,
          theme: this.theme
        };
      };
    },
    hasVisiblePageQuestions() {
      return this.page.page_questions.some(
        pageQuestion => !pageQuestion._destroy
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.main-page-body {
  min-height: 100vh;
  padding-top: 165px;
  padding-bottom: 165px;
}

.main-page-content {
  width: 500px;
  max-width: 100%;
  position: relative;
}

/*! purgecss start ignore */
:deep(.top_left_corner),
:deep(.top_right_corner),
:deep(.bottom_left_corner),
:deep(.bottom_right_corner) {
  position: absolute;
  width: 500px;

  @media (min-width: $screen-xs-min) {
    width: 650px;
  }
}

:deep(.top_left_corner) {
  top: 0;
  left: 0;
}

:deep(.top_right_corner) {
  top: 0;
  right: 0;
}

:deep(.bottom_left_corner) {
  bottom: 0;
  left: 0;
}

:deep(.bottom_right_corner) {
  bottom: 0;
  right: 0;
}
/*! purgecss end ignore */

.title {
  font-size: 19px;
}

.name {
  font-size: 24px;
  line-height: 1.6;

  @media (min-width: $screen-sm-min) {
    font-size: 28px;
    line-height: 1.83;
  }
}

.and {
  font-size: 18px;
  line-height: 1.6;

  @media (min-width: $screen-sm-min) {
    font-size: 28px;
    line-height: 1.83;
  }
}

.text-area-1,
.text-area-2,
.text-area-3 {
  font-size: 16px;
  line-height: 1.5;
}

.date {
  font-size: 19px;
  line-height: 1.5;

  @media (min-width: $screen-sm-min) {
    font-size: 24px;
  }
}

/*! purgecss start ignore */
.text :deep(a) {
  text-decoration: underline;
  word-break: break-all;
}

.text :deep(a:hover) {
  text-decoration: none;
}
/*! purgecss end ignore */
</style>
