<template>
  <div
    class="main-page-body relative m-2 sm:m-0"
    data-element="mainPageBody"
    :style="page.design.theme.mainPageBody">
    <div
      class="main-page-content mx-auto px-4 py-5 sm:p-7"
      data-element="mainPageContent"
      :style="[page.design.theme.mainPageContent, borderImageStyles]">
      <div class="text-center">
        <h1
          v-if="page.title"
          v-linkify
          data-element="title"
          class="title mb-4 text"
          :style="computedStyle(theme, 'title')"
          v-html="page.title"></h1>
        <div
          v-if="page.text_area_1"
          v-linkify
          class="text-area-1 mb-4 text"
          data-element="text_area_1"
          :style="computedStyle(theme, 'text_area_1')"
          v-html="page.text_area_1"></div>
        <div
          v-if="
            (page.first_name || page.last_name) &&
            (page.partner_first_name || page.partner_last_name)
          "
          class="names mb-4 text">
          <div
            class="name flex flex-wrap justify-center gap-x-3 md:gap-x-4"
            :style="theme.names">
            <span
              v-linkify
              data-cs-mask
              class="text first-name"
              data-element="firstName"
              :style="computedStyle(theme, 'firstName')"
              v-html="page.first_name"></span>
            <span
              v-linkify
              data-cs-mask
              class="text last-name"
              data-element="lastName"
              :style="computedStyle(theme, 'lastName')"
              v-html="page.last_name"></span>
          </div>
          <div
            :style="computedStyle(theme, 'and')"
            data-element="and"
            class="and">
            {{ theme.and.content }}
          </div>
          <div
            class="name flex flex-wrap justify-center gap-x-3 md:gap-x-4"
            :style="computedStyle(theme, 'names')">
            <span
              v-linkify
              data-cs-mask
              class="text first-name partner-first-name"
              data-element="firstName"
              :style="computedStyle(theme, 'firstName')"
              v-html="page.partner_first_name"></span>
            <span
              v-linkify
              data-cs-mask
              class="text last-name partner-last-name"
              data-element="lastName"
              :style="computedStyle(theme, 'lastName')"
              v-html="page.partner_last_name"></span>
          </div>
        </div>
        <div
          v-if="page.text_area_2"
          v-linkify
          class="text-area-2 mb-4 text"
          data-element="text_area_2"
          :style="computedStyle(theme, 'text_area_2')"
          v-html="page.text_area_2"></div>
        <div
          v-if="page.date"
          class="date mb-4"
          data-element="date"
          :style="computedStyle(theme, 'date')">
          {{ dateString }}
        </div>
        <div
          v-if="page.text_area_3"
          v-linkify
          data-element="text_area_3"
          :style="computedStyle(theme, 'text_area_3')"
          class="text-area-3 whitespace-pre-wrap text"
          v-html="page.text_area_3"></div>
      </div>
      <hr
        v-if="hasVisiblePageQuestions"
        class="hr my-7"
        data-element="hr"
        :style="computedStyle(theme, 'hr')" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import computedStyle from "../../../mixins/computedStyle";
import imageExists from "../../../mixins/imageExists";
import dates from "./dates";

/* eslint no-underscore-dangle: ["error", { "allow": ["_destroy"] }] */
export default {
  name: "PageTemplate5",
  mixins: [computedStyle, imageExists, dates],
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateOptions: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    };
  },
  computed: {
    dateString() {
      return this.date.toLocaleDateString(undefined, this.dateOptions);
    },
    theme() {
      return this.page.design.theme;
    },
    themeImageProps() {
      return position => {
        return {
          position,
          theme: this.theme
        };
      };
    },
    hasVisiblePageQuestions() {
      return this.page.page_questions.some(
        pageQuestion => !pageQuestion._destroy
      );
    },
    borderImageStyles() {
      if (this.imageExists(this.theme, "border_image")) {
        return {
          "--border-image-source-desktop": `url(${this.theme.images.border_image_desktop.url})`,
          "--border-image-source-mobile": `url(${this.theme.images.border_image_mobile.url})`
        };
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-page-body {
  min-height: 100vh;

  @media (min-width: $screen-sm-min) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.main-page-content {
  width: 600px;
  max-width: 100%;
  border-width: 24px;
  border-image-source: var(--border-image-source-mobile);
  border-image-slice: 48;
  position: relative;

  @media (min-width: $screen-sm-min) {
    border-width: 48px;
    border-image-source: var(--border-image-source-desktop);
    border-image-slice: 96;
  }
}

.title {
  font-size: 19px;
}

.name {
  font-size: 24px;
  line-height: 1.6;

  @media (min-width: $screen-sm-min) {
    font-size: 28px;
    line-height: 1.83;
  }
}

.and {
  font-size: 18px;
  line-height: 1.6;

  @media (min-width: $screen-sm-min) {
    font-size: 28px;
    line-height: 1.83;
  }
}

.text-area-1,
.text-area-2,
.text-area-3 {
  font-size: 16px;
  line-height: 1.5;
}

.date {
  font-size: 19px;
  line-height: 1.5;

  @media (min-width: $screen-sm-min) {
    font-size: 24px;
  }
}

/*! purgecss start ignore */
.text :deep(a) {
  text-decoration: underline;
  word-break: break-all;
}

.text :deep(a:hover) {
  text-decoration: none;
}
/*! purgecss end ignore */
</style>
