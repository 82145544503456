<template>
  <div>
    <h3
      class="typeset-4 question-label mb-4"
      :style="theme.questionLabel">
      {{ t("planner.pages.response.form.plus-one.add-name") }}
    </h3>

    <div class="flex flex-wrap -mx-2">
      <text-input
        v-model="guest.title"
        class="w-2/12 mb-4 px-2"
        label="Title"
        :input-id="`title-${guest.id}`"
        :theme="theme" />

      <text-input
        v-model="guest.first_name"
        class="w-full sm:w-5/12 mb-4 px-2"
        label="First name"
        :input-id="`first-name-${guest.id}`"
        :errors="formErrors[`first-name-${guest.id}`]"
        required
        :theme="theme" />

      <text-input
        v-model="guest.last_name"
        class="w-full sm:w-5/12 mb-4 px-2"
        label="Last name"
        :input-id="`last-name-${guest.id}`"
        :errors="formErrors[`last-name-${guest.id}`]"
        required
        :theme="theme" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextInput from "@/app/javascript/components/shared/TextInput.vue";

export default {
  name: "PlusOneNameQuestion",
  components: {
    TextInput
  },
  props: {
    guest: {
      type: Object,
      required: false
    },
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      formErrors: state => state.formErrors
    }),
    theme() {
      return this.page.design.theme;
    }
  }
};
</script>
