<template>
  <div>
    <picture
      v-if="
        imageExists(`${position}_desktop`) && imageExists(`${position}_mobile`)
      ">
      <source
        media="(min-width: 500px)"
        :srcset="theme.images[`${position}_desktop`].url" />
      <img
        :src="theme.images[`${position}_mobile`].url"
        :class="position" />
    </picture>
    <img
      v-else-if="imageExists(`${position}_desktop`)"
      :src="theme.images[`${position}_desktop`].url"
      :class="position"
      class="hidden sm:block" />
    <img
      v-else-if="imageExists(`${position}_mobile`)"
      :src="theme.images[`${position}_mobile`].url"
      :class="position"
      class="sm:hidden" />
  </div>
</template>

<script>
export default {
  name: "ThemeImage",
  props: {
    position: {
      type: String,
      required: true
    },
    theme: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageExists() {
      return imagePosition => {
        return (
          this.theme.images &&
          this.theme.images[imagePosition] &&
          this.theme.images[imagePosition].url
        );
      };
    }
  }
};
</script>
