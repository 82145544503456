<template>
  <div class="address-response">
    <h3
      class="typeset-4 question-label mb-4"
      data-element="questionLabel"
      :style="computedStyle(theme, 'questionLabel')">
      {{ question.question }}
      <span
        v-if="question.required"
        class="text-red">
        *
      </span>
    </h3>
    <div>
      <text-input
        v-model="address.line_1"
        data-element="textInput"
        placeholder="Address Name"
        :required="question.required"
        :errors="formErrors['address']"
        input-id="address-response-1"
        class="mb-4"
        :theme="page.design.theme" />
      <text-input
        v-model="address.line_2"
        data-element="textInput"
        placeholder="Line 1"
        input-id="address-response-2"
        class="mb-4"
        :theme="page.design.theme" />
      <text-input
        v-model="address.line_3"
        data-element="textInput"
        placeholder="Line 2"
        input-id="address-response-3"
        class="mb-4"
        :theme="page.design.theme" />
      <text-input
        v-model="address.line_4"
        data-element="textInput"
        placeholder="Line 3"
        input-id="address-response-4"
        class="mb-4"
        :theme="page.design.theme" />
      <text-input
        v-model="address.line_5"
        data-element="textInput"
        placeholder="Line 4"
        input-id="address-response-5"
        class="mb-4"
        :theme="page.design.theme" />
      <text-input
        v-model="address.line_6"
        data-element="textInput"
        placeholder="Country"
        input-id="address-response-6"
        class="mb-4"
        :theme="page.design.theme" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextInput from "@/app/javascript/components/shared/TextInput.vue";
import computedStyle from "../../../mixins/computedStyle";

export default {
  name: "AddressQuestion",
  components: {
    TextInput
  },
  mixins: [computedStyle],
  props: {
    address: {
      type: Object,
      required: true
    },
    page: {
      type: Object,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      formErrors: state => state.formErrors
    }),
    theme() {
      return this.page.design.theme;
    }
  }
};
</script>
