export default {
  computed: {
    imageExists() {
      return (theme, imagePosition) => {
        return (
          (theme.images &&
            theme.images[`${imagePosition}_desktop`] &&
            theme.images[`${imagePosition}_desktop`].url) ||
          (theme.images &&
            theme.images[`${imagePosition}_mobile`] &&
            theme.images[`${imagePosition}_mobile`].url)
        );
      };
    }
  }
};
