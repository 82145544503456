<template>
  <div :class="{ 'has-errors': guest && formErrors[`rsvp-${guest.id}`] }">
    <h3
      class="typeset-4 question-label mb-4"
      data-element="questionLabel"
      :style="computedStyle(theme, 'questionLabel')">
      {{ question.question }}
      <span
        v-if="question.required"
        class="text-red">
        *
      </span>
    </h3>

    <div class="flex px-px">
      <button
        v-for="(choice, index) in question.choices"
        :key="index"
        class="btn btn-large rsvp-btn flex-1"
        :class="{ selected: isSelected(choice), '-mx-px': index === 0 }"
        :style="[theme.rsvpButton, selectedStyleProps(choice)]"
        data-element="rsvpButton"
        @click="setRsvp(choice)">
        {{ choice }}
      </button>
    </div>

    <span
      v-if="guest && formErrors[`rsvp-${guest.id}`]"
      class="error-box mt-2">
      {{ formErrors[`rsvp-${guest.id}`][0] }}
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import computedStyle from "../../../mixins/computedStyle";

export default {
  name: "RsvpQuestion",
  mixins: [computedStyle],
  props: {
    question: {
      type: Object,
      required: true
    },
    guest: {
      type: Object,
      default: null
    },
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      formErrors: state => state.formErrors
    }),
    theme() {
      return this.page.design.theme;
    },
    isSelected() {
      return choice => {
        if (!this.guest) return false;
        return choice === this.guest.rsvp;
      };
    },
    selectedStyleProps() {
      return choice => {
        if (!this.isSelected(choice)) return null;
        return this.theme.rsvpButton.selected;
      };
    }
  },
  methods: {
    setRsvp(choice) {
      if (this.guest) {
        this.guest.rsvp = choice;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  &:focus {
    position: relative;
    z-index: 1;
  }
}
</style>
