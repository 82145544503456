<template>
  <div>
    <rsvp-question
      v-if="rsvpPageQuestion"
      :question="rsvpPageQuestion && rsvpPageQuestion.question"
      :guest="guest"
      :preview="preview"
      :page="page"
      class="mb-6" />
    <slide-transition>
      <div v-if="shouldShowQuestions">
        <component
          :is="pageQuestionBlockName(question)"
          v-for="{ question } in nonRsvpPageQuestions"
          :key="question.id"
          v-bind="propsForQuestionBlock(question)"
          class="mb-6" />

        <div v-if="showPlusOneSection">
          <plus-one-response :page="page" />
        </div>
      </div>
    </slide-transition>
    <slide-transition>
      <div
        v-if="guest && guest.rsvp === 'no'"
        class="py-5">
        <h3
          class="question-label typeset-4 mb-4"
          :style="theme.questionLabel">
          {{ t("planner.pages.response.form.send-message") }}
        </h3>
        <textarea-input
          v-model="body"
          input-id="body"
          :theme="theme"
          rows="4"
          class="mb-6" />
      </div>
    </slide-transition>

    <div
      v-if="visiblePageQuestions.length === 0 && plusOnes && plusOnes.length"
      class="px-6 py-4 mt-5"
      :style="theme.plusOneResponseHeader">
      <span
        class="block text-center"
        :style="theme.genericText">
        {{ guest.plus_ones_names_formatted }}
      </span>
    </div>

    <div
      v-if="shouldShowResponseButton"
      class="text-center">
      <hr
        class="my-7 hr"
        data-element="hr"
        :style="theme.hr" />
      <btn
        type="secondary"
        data-element="button"
        class="btn-large mb-5"
        :theme="theme"
        @click="checkRsvpAndSend">
        {{ t("planner.pages.response.form.send-response") }}
      </btn>

      <p
        data-element="genericText"
        :style="computedStyle(theme, 'genericText')"
        class="responding-for-text mb-3">
        {{
          t("planner.pages.response.form.responding-for", {
            guest: guest.full_name
          })
        }}
      </p>
      <a
        href="#"
        class="not-person-link"
        data-element="genericLink"
        :style="[computedStyle(theme, 'genericText'), theme.genericLink]"
        @click.prevent="returnToGuestId">
        {{
          t("planner.pages.response.form.not-person", {
            guest: guest.full_name
          })
        }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Btn from "@/app/javascript/components/shared/Btn.vue";
import TextareaInput from "@/app/javascript/components/shared/TextareaInput.vue";
import SlideTransition from "@/app/javascript/components/transitions/SlideTransition.vue";
import api from "../../api/guestSide";
import computedStyle from "../../mixins/computedStyle";

/* eslint no-underscore-dangle: ["error", { "allow": ["_destroy"] }] */

import RsvpQuestion from "./blocks/RsvpQuestion.vue";
import TextQuestion from "./blocks/TextQuestion.vue";
import MultiQuestion from "./blocks/MultiQuestion.vue";
import AddressQuestion from "./blocks/AddressQuestion.vue";
import PlusOneResponse from "./PlusOneResponse.vue";
import ResponseConfirmation from "./ResponseConfirmation.vue";

export default {
  name: "Page",
  components: {
    Btn,
    RsvpQuestion,
    TextQuestion,
    MultiQuestion,
    AddressQuestion,
    PlusOneResponse,
    ResponseConfirmation,
    TextareaInput,
    SlideTransition
  },
  mixins: [computedStyle],
  props: {
    page: {
      type: Object,
      required: true
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      finished: false,
      loading: false,
      body: "",
      errors: {},
      address: {
        line_1: "",
        line_2: "",
        line_3: "",
        line_4: "",
        line_5: "",
        line_6: ""
      }
    };
  },
  computed: {
    ...mapState({
      guest: state => state.guest,
      plusOnes: state => state.plusOnes,
      formErrors: state => state.formErrors,
      event: state => state.event
    }),
    pageQuestionBlockName() {
      return question => {
        return `${this.formattedQuestionType(question)}Question`;
      };
    },
    propsForQuestionBlock() {
      return question => {
        // All question types need these props
        const commonProps = {
          question,
          page: this.page
        };
        // Address needs slightly different props to other questions
        let otherProps;
        if (this.formattedQuestionType(question) === "Address") {
          otherProps = {
            address: this.address
          };
        } else {
          otherProps = {
            guest: this.guest
          };
        }
        // Combine the two
        return { ...commonProps, ...otherProps };
      };
    },
    formattedQuestionType() {
      return question => {
        const split = question.type.split("::");
        return split[split.length - 1];
      };
    },
    rsvpPageQuestion() {
      return this.visiblePageQuestions.find(
        pageQuestion => pageQuestion.question.type === "Planner::Question::Rsvp"
      );
    },
    nonRsvpPageQuestions() {
      return this.visiblePageQuestions.filter(
        pageQuestion => pageQuestion.question.type !== "Planner::Question::Rsvp"
      );
    },
    visiblePageQuestions() {
      return this.page.page_questions.filter(
        pageQuestion => !pageQuestion._destroy
      );
    },
    questionsForPlusOnes() {
      return this.visiblePageQuestions.filter(
        pageQuestion => pageQuestion.question.extend_to_plus_ones
      );
    },
    shouldShowQuestions() {
      if (!this.rsvpPageQuestion) return true;

      return this.preview || (this.guest && this.guest.rsvp !== "no");
    },
    shouldShowResponseButton() {
      return this.guest && this.guest.id && this.visiblePageQuestions.length;
    },
    showPlusOneSection() {
      if (this.preview) {
        return false;
      }
      return (
        this.plusOnes.length &&
        this.questionsForPlusOnes.length &&
        this.guest.rsvp !== "no"
      );
    },
    theme() {
      return this.page.design.theme;
    }
  },
  methods: {
    toggleFormErrors(errors = {}) {
      this.$store.commit("setFormErrors", errors);
    },
    checkRsvpAndSend() {
      this.$store.commit("setLoading", true);
      this.clearValidationErrors();
      if (this.guest.rsvp === "no") {
        this.setAllGuestsRsvpToNo();
        if (this.body) {
          this.sendRsvpDeclineEmail();
        } else {
          this.sendAllResponses();
        }
      } else {
        this.sendAllResponses();
      }
    },
    setAllGuestsRsvpToNo() {
      this.plusOnes.forEach(plusOne => {
        plusOne.rsvp = "no";
      });
    },
    sendAllResponses(rsvpNo = false) {
      const payload = this.buildResponsePayload(rsvpNo);

      if (Object.keys(this.errors).length) {
        this.toggleFormErrors(this.errors);
        this.$store.commit("setLoading", false);
      } else {
        api.respond(this.page.slug, payload).then(() => {
          this.$store.commit("setLoading", false);
          this.$store.commit("setFinished", true);
        });
      }
    },
    buildResponsePayload(rsvpNo) {
      const answerParams = {};
      this.visiblePageQuestions.forEach(pageQuestion => {
        const questionId = pageQuestion.question.id;
        switch (pageQuestion.question.type) {
          case "Planner::Question::Rsvp":
            answerParams[questionId] = this.buildRsvpObject(
              pageQuestion.question
            );
            break;
          case "Planner::Question::Address":
            if (pageQuestion.question.required || this.address.line_1) {
              answerParams[questionId] = this.buildAddressObject(
                pageQuestion.question
              );
            }
            break;
          default:
            answerParams[questionId] = this.buildOtherObject(
              pageQuestion.question
            );
        }
      });

      return {
        answers_data: answerParams,
        guests_data: this.showPlusOneSection ? this.buildNameObject() : {},
        lead_guest_id: this.guest.id,
        skip_notification: rsvpNo
      };
    },
    sendRsvpDeclineEmail() {
      api
        .email(this.page.slug, {
          guest_email: this.guest.email,
          message: this.body,
          simple_format: true
        })
        .then(() => {
          this.sendAllResponses(true);
        });
    },
    clearValidationErrors() {
      this.toggleFormErrors();
      this.errors = {};
    },
    requiredFieldPresent(fieldLabel, responseRequired, response) {
      if (!responseRequired || (response && response !== "unknown")) {
        return true;
      }
      if (
        this.guest.rsvp !== "no" &&
        responseRequired &&
        (!response || response === "unknown")
      ) {
        this.errors[fieldLabel] = [
          this.t("planner.pages.response.form.error.required-field")
        ];
      }
      return false;
    },
    buildNameObject() {
      const params = {};
      this.plusOnes.forEach(plusOne => {
        if (plusOne.rsvp !== "no" && !plusOne.full_name) {
          if (
            this.requiredFieldPresent(
              `first-name-${plusOne.id}`,
              true,
              plusOne.first_name
            ) &&
            this.requiredFieldPresent(
              `last-name-${plusOne.id}`,
              true,
              plusOne.last_name
            )
          ) {
            params[plusOne.id] = {
              first_name: plusOne.first_name,
              last_name: plusOne.last_name,
              title: plusOne.title
            };
          }
        }
      });
      return params;
    },
    buildRsvpObject(question) {
      const rsvpObject = {};
      if (
        this.requiredFieldPresent(
          `rsvp-${this.guest.id}`,
          question.required,
          this.guest.rsvp
        )
      ) {
        rsvpObject[this.guest.id] = this.guest.rsvp;
      }
      this.plusOnes.forEach(guest => {
        if (
          this.requiredFieldPresent(
            `rsvp-${guest.id}`,
            question.required,
            guest.rsvp
          )
        ) {
          rsvpObject[guest.id] = guest.rsvp;
        }
      });
      return rsvpObject;
    },
    buildAddressObject(question) {
      const addressObject = {};
      if (this.guest.rsvp !== "no") {
        if (
          this.requiredFieldPresent(
            "address",
            question.required,
            this.address.line_1
          )
        ) {
          addressObject[this.guest.id] = { address: this.address };
        }
      }
      return addressObject;
    },
    buildOtherObject(question) {
      const object = {};
      const mainGuestResponse = this.guest.answers_data[question.id];

      if (
        this.requiredFieldPresent(
          `guest-${this.guest.id}-${question.id}`,
          question.required,
          mainGuestResponse
        )
      ) {
        object[this.guest.id] = mainGuestResponse;
      }
      if (question.extend_to_plus_ones) {
        this.plusOnes.forEach(guest => {
          if (guest.rsvp !== "no") {
            const response = guest.answers_data[question.id];
            if (
              (guest,
              this.requiredFieldPresent(
                `guest-${guest.id}-${question.id}`,
                question.required,
                response
              ))
            ) {
              object[guest.id] = response;
            }
          }
        });
      }
      return object;
    },
    returnToGuestId() {
      this.$store.commit("setGuest", null);
      this.$store.commit("setPlusOnes", null);
      this.toggleFormErrors();
    }
  }
};
</script>
