<template>
  <div class="success-page flex flex-col text-center">
    <div class="flex-1 flex items-center justify-center px-4 py-5">
      <div>
        <p class="typeset-2 mb-4">
          {{ t("planner.pages.response.form.confirmation.title") }}
        </p>
        <p class="typeset-7">
          {{ t("planner.pages.response.form.confirmation.message") }}
          <br />
          {{ event.host_name }}
        </p>
      </div>
    </div>

    <div class="footer bg-paper-1 px-4 py-8">
      <img
        :src="papierLogo"
        alt="papier logo"
        class="img-responsive mx-auto pb-4" />
      <div class="typeset-8 mb-3">
        {{ t("planner.pages.response.form.footer-text") }}
      </div>
      <div class="typeset-8 underline cursor-pointer">
        <a href="http://papier.com/wedding/online">
          {{ t("planner.pages.response.form.find-out-more") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// eslint-disable-next-line import/no-unresolved
import papierLogo from "@/app/assets/images/papierlogo.svg?url";

export default {
  name: "ResponseConfirmation",
  data() {
    return {
      papierLogo
    };
  },
  computed: {
    ...mapState({
      event: state => state.event
    })
  }
};
</script>
<style lang="scss" scoped>
.success-page {
  min-height: 100vh;
  width: 534px;
  max-width: 100%;
  margin: 0 auto;
}

.footer {
  width: 320px;
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}
</style>
