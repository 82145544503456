<template>
  <div class="plus-one-response mb-6">
    <div
      class="plus-one-response-header -mx-5 sm:-mx-7 mb-6 px-5 py-7"
      :style="theme.plusOneResponseHeader">
      <p
        class="plus-one-response-heading"
        :style="computedStyle(theme, 'plusOneResponseHeading')">
        {{ plusOneHeading }}
      </p>
      <p
        class="plus-one-response-subheading"
        :style="computedStyle(theme, 'plusOneResponseSubheading')">
        {{ plusOneSubheading }}
      </p>
    </div>
    <div
      v-for="(plusOne, index) in plusOnes"
      :key="plusOne.id">
      <div v-if="plusOnes.length > 1">
        <p
          :style="computedStyle(theme, 'plusOneResponseHeading')"
          class="plus-one-response-heading mb-6">
          {{ guestLabel(index, plusOne) }}
        </p>
      </div>

      <div v-if="plusOne.rsvp !== 'no'">
        <plus-one-name-question
          v-if="!plusOne.full_name && plusOne.rsvp !== 'no'"
          :guest="plusOne"
          :page="page"
          class="mb-6" />
        <p
          :style="computedStyle(theme, 'plusOneResponseHeading')"
          class="plus-one-response-heading text-center mb-6">
          {{ plusOneFormLabel(plusOne) }}
        </p>
      </div>

      <component
        :is="pageQuestionBlockName(question)"
        v-for="{ question } in questionsForPlusOne(plusOne)"
        :key="question.id"
        :question="question"
        :guest="plusOne"
        :page="page"
        class="mb-6" />

      <hr
        v-if="index < plusOnes.length - 1"
        :theme="theme.hr"
        class="my-6 hr" />
    </div>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_destroy"] }] */
import { mapState } from "vuex";
import computedStyle from "../../mixins/computedStyle";
import RsvpQuestion from "./blocks/RsvpQuestion.vue";
import TextQuestion from "./blocks/TextQuestion.vue";
import MultiQuestion from "./blocks/MultiQuestion.vue";
import AddressQuestion from "./blocks/AddressQuestion.vue";
import PlusOneNameQuestion from "./blocks/PlusOneNameQuestion.vue";

export default {
  name: "PlusOneResponse",
  components: {
    RsvpQuestion,
    TextQuestion,
    MultiQuestion,
    AddressQuestion,
    PlusOneNameQuestion
  },
  mixins: [computedStyle],
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      plusOnes: state => state.plusOnes
    }),
    pageQuestionBlockName() {
      return question => {
        return `${this.formattedQuestiontype(question)}Question`;
      };
    },
    formattedQuestiontype() {
      return question => {
        const split = question.type.split("::");
        return split[split.length - 1];
      };
    },
    visiblePageQuestions() {
      return this.page.page_questions.filter(
        pageQuestion => !pageQuestion._destroy
      );
    },
    questionsForPlusOne() {
      return plusOne => {
        return this.visiblePageQuestions.filter(
          pageQuestion =>
            pageQuestion.question.extend_to_plus_ones &&
            this.showQuestionForPlusOne(plusOne, pageQuestion.question)
        );
      };
    },
    showQuestionForPlusOne() {
      return (plusOne, question) => {
        return (
          plusOne.rsvp !== "no" ||
          this.formattedQuestiontype(question) === "Rsvp"
        );
      };
    },
    plusOneSubheading() {
      if (this.plusOnes.length === 1) {
        return this.t(
          "planner.pages.response.form.plus-one.subheading-singular"
        );
      }
      return this.t("planner.pages.response.form.plus-one.subheading-plural", {
        count: this.plusOnes.length
      });
    },
    plusOneHeading() {
      return this.plusOnes.length === 1
        ? this.t("planner.pages.response.form.plus-one.heading-singular")
        : this.t("planner.pages.response.form.plus-one.heading-plural");
    },
    theme() {
      return this.page.design.theme;
    },
    guestLabel() {
      return (guestIndex, guest) => {
        const baseLabel = `${this.stringifyNumber(guestIndex)} plus one`;
        if (guest.first_name && guest.rsvp === "no") {
          return `${baseLabel} - ${guest.first_name}`;
        }
        return baseLabel;
      };
    }
  },
  methods: {
    stringifyNumber(n) {
      const special = [
        "First",
        "Second",
        "Third",
        "Fourth",
        "Fifth",
        "Sixth",
        "Seventh",
        "Eighth",
        "Ninth",
        "Tenth",
        "Eleventh",
        "Twelfth",
        "Thirteenth",
        "Fourteenth",
        "Fifteenth",
        "Sixteenth",
        "Seventeenth",
        "Eighteenth",
        "Nineteenth"
      ];
      const deca = [
        "Twent",
        "Thirt",
        "Fort",
        "Fift",
        "Sixt",
        "Sevent",
        "Eight",
        "Ninet"
      ];
      if (n < 20) return special[n];
      if (n % 10 === 0) return `${deca[Math.floor(n / 10) - 2]}ieth`;
      return `${deca[Math.floor(n / 10) - 2]}y-${special[n % 10]}`;
    },
    plusOneFormLabel(plusOne) {
      if (plusOne.first_name) {
        return this.t(
          "planner.pages.response.form.plus-one.respond-label-with-name",
          {
            name: plusOne.first_name
          }
        );
      }
      return this.t(
        "planner.pages.response.form.plus-one.respond-label-no-name"
      );
    }
  }
};
</script>
