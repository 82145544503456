<template>
  <div
    class="guest-id-body flex"
    :style="theme.mainPageBody">
    <div
      class="guest-id-content m-auto p-5"
      :style="theme.mainPageContent">
      <div class="py-5">
        <contact-organiser
          v-if="contactOrganiser"
          :page="page"
          @closed="contactOrganiser = false"
          @message-sent="messageSent" />
        <div v-if="noSuggestionsFound">
          <a
            href="#"
            class="generic-link inline-flex items-center mb-4 underline"
            :style="[computedStyle(theme, 'genericText'), theme.genericLink]"
            @click.prevent="goBack">
            <svg-icon
              name="leftarrow-small-thin"
              class="mr-4" />
            {{ t("planner.pages.response.guest-id.no-matches.back") }}
          </a>
          <div
            class="generic-text mb-4"
            :style="computedStyle(theme, 'genericText')">
            {{
              t("planner.pages.response.guest-id.no-matches.p1", {
                firstName,
                lastName
              })
            }}
          </div>
          <div
            class="generic-text mb-4"
            :style="computedStyle(theme, 'genericText')">
            {{ t("planner.pages.response.guest-id.no-matches.p2") }}
          </div>
          <a
            href="#"
            class="underline mb-4"
            @click.prevent="contactOrganiser = true">
            {{ t("planner.pages.response.guest-id.no-matches.contact") }}
          </a>
        </div>
        <div v-else-if="!suggestions">
          <div
            class="generic-text mb-5"
            :style="computedStyle(theme, 'genericText')">
            {{ t("planner.pages.response.guest-id.intro") }}
          </div>
          <div
            action="#"
            class="flex -mx-3 mb-5">
            <div class="px-3 w-1/2">
              <text-input
                v-model="firstName"
                :errors="errors.firstName"
                required
                input-id="firstName"
                label="First name"
                class="mb-4"
                :theme="theme"
                @update:model-value="errors.firstName = []"
                @keydown.enter="idGuest" />
            </div>
            <div class="px-3 w-1/2">
              <text-input
                v-model="lastName"
                :errors="errors.lastName"
                required
                input-id="lastName"
                label="Last name"
                class="mb-4"
                :theme="theme"
                @update:model-value="errors.lastName = []"
                @keydown.enter="idGuest" />
            </div>
          </div>
          <btn
            ref="btn"
            type="secondary"
            class="btn-large"
            :promise="next()"
            :theme="theme">
            {{ t("planner.pages.response.guest-id.next") }}
          </btn>
        </div>
        <div v-if="suggestions">
          <a
            href="#"
            class="generic-link inline-flex items-center mb-4 underline"
            :style="[computedStyle(theme, 'genericText'), theme.genericLink]"
            @click.prevent="goBack">
            <svg-icon
              name="leftarrow-small-thin"
              class="mr-3" />
            {{ t("planner.pages.response.guest-id.suggestions.back") }}
          </a>
          <div
            class="generic-text mb-4"
            :style="computedStyle(theme, 'genericText')">
            {{
              t("planner.pages.response.guest-id.suggestions.intro", {
                firstName,
                lastName
              })
            }}
          </div>
          <div class="mb-5">
            <div>
              <radio-input
                v-for="suggestion in suggestions"
                :key="suggestion.id"
                class="mb-3"
                :value="suggestion.id"
                :checked="suggestedGuest === suggestion"
                input-id="suggested-guest"
                :label="suggestion.full_name"
                @input="suggestedGuest = suggestion" />
            </div>
          </div>
          <div class="flex items-center">
            <btn
              type="secondary"
              class="btn-large w-1/2 mr-4"
              :theme="theme"
              @click="setGuestandPlusOnes(suggestedGuest)">
              {{ t("planner.pages.response.guest-id.suggestions.cta") }}
            </btn>
            <div
              class="generic-text w-1/2"
              :style="computedStyle(theme, 'genericText')">
              {{
                t("planner.pages.response.guest-id.suggestions.cant-see-name")
              }}
              <br />
              <a
                href="#"
                class="underline"
                @click.prevent="contactOrganiser = true">
                {{ t("planner.pages.response.guest-id.suggestions.contact") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/app/javascript/components/shared/Btn.vue";
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";
import TextInput from "@/app/javascript/components/shared/TextInput.vue";
import RadioInput from "@/app/javascript/components/shared/RadioInput.vue";
import computedStyle from "../../mixins/computedStyle";
import api from "../../api/guestSide";
import ContactOrganiser from "./ContactOrganiser.vue";

export default {
  name: "GuestId",
  components: {
    Btn,
    TextInput,
    RadioInput,
    SvgIcon,
    ContactOrganiser
  },
  mixins: [computedStyle],
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      errors: {
        firstName: [],
        lastName: []
      },
      suggestions: null,
      suggestedGuest: null,
      noSuggestionsFound: false,
      contactOrganiser: false
    };
  },
  computed: {
    theme() {
      return this.page.design.theme;
    }
  },
  methods: {
    setGuestandPlusOnes(guest) {
      this.$store.commit("setGuest", guest);
      this.$store.commit("setPlusOnes", guest.plus_ones);
    },
    goBack() {
      this.firstName = "";
      this.lastName = "";
      this.noSuggestionsFound = null;
      this.suggestions = null;
    },
    idGuest() {
      this.$refs.btn.handleClick();
    },
    messageSent() {
      this.contactOrganiser = false;
      this.goBack();
    },
    next() {
      return () => {
        return new Promise((resolve, reject) => {
          if (!this.firstName.trim().length) {
            this.errors.firstName = ["You need to add a first name"];
            reject();
          } else {
            this.errors.firstName = [];
          }

          if (!this.lastName.trim().length) {
            this.errors.lastName = ["You need to add a last name"];
            reject();
          } else {
            this.errors.lastName = [];
          }

          if (
            this.errors.firstName.length === 0 &&
            this.errors.lastName.length === 0
          ) {
            api
              .index(this.page.slug, {
                first_name: this.firstName,
                last_name: this.lastName
              })
              .then(response => {
                const { match, suggestions } = response.data;
                if (match) {
                  this.setGuestandPlusOnes(match);
                } else if (suggestions.length) {
                  this.suggestions = suggestions;
                } else {
                  this.noSuggestionsFound = true;
                }
                resolve();
              });
          }
        });
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.guest-id-body {
  min-height: 100vh;
  border-width: 16px;

  @media (min-width: $screen-sm-min) {
    border-width: 40px;
  }
}

.guest-id-content {
  width: 534px;
  max-width: 100%;
  max-height: calc(100vh - 2rem);
  overflow-y: scroll;
}
</style>
