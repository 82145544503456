<template>
  <div>
    <modal
      open
      class="contact-organiser-modal"
      prevent-background-close
      @closed="$emit('closed')">
      <template #header>
        <h3 class="typeset-4 ellipsis">
          {{ t("planner.pages.response.contact-organiser.heading") }}
        </h3>
      </template>
      <template #body>
        <p
          class="mb-4"
          :style="computedStyle(page.design.theme, 'genericText')">
          {{ t("planner.pages.response.contact-organiser.explanation") }}
        </p>
        <text-input
          v-model="email"
          type="email"
          input-id="email"
          :label="t('planner.pages.response.contact-organiser.labels.email')"
          :errors="errors.email"
          required
          class="mb-4"
          :theme="page.design.theme" />
        <textarea-input
          v-model="body"
          input-id="body"
          :label="t('planner.pages.response.contact-organiser.labels.message')"
          :errors="errors.body"
          required
          class="mb-4"
          :theme="page.design.theme" />
        <btn
          :promise="sendEmail()"
          type="secondary"
          :theme="page.design.theme"
          class="btn-large">
          {{ t("planner.pages.response.contact-organiser.cta") }}
        </btn>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/app/javascript/components/shared/Modal.vue";
import TextInput from "@/app/javascript/components/shared/TextInput.vue";
import TextareaInput from "@/app/javascript/components/shared/TextareaInput.vue";
import Btn from "@/app/javascript/components/shared/Btn.vue";
import computedStyle from "../../mixins/computedStyle";
import api from "../../api/guestSide";

export default {
  name: "ContactOrganiser",
  components: {
    Modal,
    TextInput,
    TextareaInput,
    Btn
  },
  mixins: [computedStyle],
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  emits: ["message-sent", "closed"],
  data() {
    return {
      email: "",
      body: "",
      errors: {
        email: [],
        body: []
      }
    };
  },
  methods: {
    sendEmail() {
      return () => {
        return new Promise((resolve, reject) => {
          if (!this.email.trim().length) {
            this.errors.email = [
              "You need to add an email before sending your message"
            ];
            reject();
            return;
          }
          if (!/\S+@\S+\.\S+/.test(this.email)) {
            this.errors.email = ["Please enter a valid email address"];
            reject();
            return;
          }
          this.errors.email = [];

          if (!this.body.trim().length) {
            this.errors.body = [
              "You need to add a message before sending your email"
            ];
            reject();
            return;
          }
          this.errors.body = [];

          api
            .email(this.page.slug, {
              guest_email: this.email,
              message: this.body
            })
            .then(() => {
              this.$store.dispatch("flashMessages/showFlashMessage", {
                message:
                  "planner.pages.response.contact-organiser.flash-message",
                type: "success"
              });
              resolve();
              this.$emit("message-sent");
            });
        });
      };
    }
  }
};
</script>

<style>
.contact-organiser-modal textarea {
  min-height: 200px;
}
</style>
