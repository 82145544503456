<template>
  <div>
    <slot></slot>
    <slot name="highlighter"></slot>
  </div>
</template>

<script>
export default {
  name: "GlobalTemplate2"
};
</script>

<style lang="scss" scoped>
/*! purgecss start ignore */
// Styles for elements in child components
// that we want to control from this template
:deep(.hr) {
  width: 80px;
}

// The following styles pertain to the questions,
// which are inserted via the <slot /> in PageTemplateX.vue.
:deep(.question-label) {
  font-size: 16px;
  line-height: 1.75;
}

// Styles for the layout of the <select>
// element in this template.
:deep(.themed-select) {
  height: 46px;
  font-size: 14px;
  position: relative;
  background-color: transparent;
  padding-right: 32px;
  padding-left: 8px;
  background-image: url("@assets/images/svg/downarrow-small-thin.svg");
  background-repeat: no-repeat;
  background-size: 10px 6px;
  background-position: right 0.5rem center;

  // Base styles for the select - could be moved to a global file
  width: 100%;
  cursor: pointer;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  max-width: none;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

// Styles for the layout of the <text-input>
// component in this template.
:deep(.themed-text-input) {
  display: block;
  width: 100%;
  padding: 2px 10px 2px;
  height: 46px;
  font-size: 1rem;
  font-weight: normal;
  border-width: 1px;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

// Styles for the layout of the <textarea-input>
// component in this template.
:deep(.themed-textarea) {
  display: block;
  width: 100%;
  padding: 2px 10px 2px;
  font-size: 1rem;
  font-weight: normal;
  border-width: 1px;
  transition: background 0.1s ease;
}

// Styles for the layout of the <btn>
// component in this template.
:deep(.themed-button) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: normal;
  transition: background 0.1s ease;
}

// Styles for the layout of the rsvp buttons
// in this template.
:deep(.rsvp-btn) {
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  border-style: solid;

  .selected {
    z-index: 1;
  }
}

// Plus one section
:deep(.plus-one-response-header) {
  text-align: center;
}

:deep(.plus-one-response-heading) {
  font-size: 14px;
  line-height: 2;
  text-transform: uppercase;
}

:deep(.plus-one-response-subheading) {
  font-size: 13px;
  line-height: 1.4;
}

:deep(.responding-for-text) {
  font-size: 12px;
}

:deep(.not-person-link) {
  font-size: 12px;
  text-decoration: underline;
}

:deep(.generic-text) {
  font-size: 14px;
}

:deep(.generic-link) {
  font-size: 14px;
  text-decoration: underline;
}
/*! purgecss end ignore */
</style>
