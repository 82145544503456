<template>
  <div
    class="multi-response"
    :class="{ 'has-errors': guest && hasErrors }">
    <h3
      class="typeset-4 question-label mb-4"
      data-element="questionLabel"
      :style="computedStyle(theme, 'questionLabel')">
      {{ question.question }}
      <span
        v-if="question.required"
        class="text-red">
        *
      </span>
    </h3>

    <template v-if="guest">
      <select
        v-model="guest.answers_data[question.id]"
        :style="selectThemeProps"
        :class="[
          { 'error-select-box': hasErrors },
          theme ? 'themed-select' : 'form-control'
        ]"
        class="mb-4">
        <option
          selected
          disabled
          value="">
          -
        </option>
        <option
          v-for="(option, index) in question.choices"
          :key="index"
          :value="option">
          {{ option }}
        </option>
      </select>
      <div
        v-if="hasErrors"
        class="error-box mt-2">
        {{ formErrors[`guest-${guest.id}-${question.id}`][0] }}
      </div>
    </template>

    <select
      v-else
      data-element="select"
      :style="selectThemeProps"
      :class="theme ? 'themed-select' : 'form-control'"
      class="mb-4">
      <option
        v-for="(option, index) in question.choices"
        :key="index"
        :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import computedStyle from "../../../mixins/computedStyle";

export default {
  name: "MultiQuestion",
  mixins: [computedStyle],
  props: {
    question: {
      type: Object,
      required: true
    },
    guest: {
      type: Object,
      required: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      formErrors: state => state.formErrors
    }),
    hasErrors() {
      return this.formErrors[`guest-${this.guest.id}-${this.question.id}`];
    },
    theme() {
      return this.page.design.theme;
    },
    selectThemeProps() {
      if (this.theme) {
        return {
          borderRadius: this.theme.select.borderRadius,
          borderWidth: this.theme.select.borderWidth,
          fontFamily: this.theme.select.fontFamily,
          letterSpacing: this.theme.select.letterSpacing,
          "--select-color": this.theme.select.color,
          "--select-hover-color": this.theme.select.hover.color,
          "--select-focus-color": this.theme.select.focus.color,
          "--select-background-color": this.theme.select.backgroundColor,
          "--select-hover-background-color":
            this.theme.select.hover.backgroundColor,
          "--select-focus-background-color":
            this.theme.select.focus.backgroundColor,
          "--select-border-color": this.theme.select.borderColor,
          "--select-hover-border-color": this.theme.select.hover.borderColor,
          "--select-focus-border-color": this.theme.select.focus.borderColor,
          "--select-box-shadow": this.theme.select.boxShadow,
          "--select-hover-box-shadow": this.theme.select.hover.boxShadow,
          "--select-focus-box-shadow": this.theme.select.focus.boxShadow
        };
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.multi-response {
  .error-select-box {
    border-color: #f17f75;
    border-radius: 0 0 3px 3px;
  }
}

.themed-select {
  // Set some defaults, in case these aren't set in the custom theme.
  --select-color: #343434;
  --select-hover-color: #343434;
  --select-focus-color: #343434;
  --select-background-color: #efefef;
  --select-border-color: #efefef;
  --select-hover-background-color: #ffffff;
  --select-hover-border-color: #121212;
  --select-box-shadow: inset 0 2px 4px 0 rgba(0, 37, 84, 0.08);

  // Certain props set using CSS variables
  // so we can override on hover/focus
  background-color: var(--select-background-color);
  border-color: var(--select-border-color);
  box-shadow: var(--select-box-shadow);
  color: var(--select-color);

  // Interaction styles (hover/focus) must use css variables
  &:hover {
    color: var(--select-hover-color);
    background-color: var(--select-hover-background-color);
    box-shadow: var(--select-hover-box-shadow);
    border-color: var(--select-hover-border-color);
  }

  &:focus {
    color: var(--select-focus-color);
    background-color: var(--select-focus-background-color);
    box-shadow: var(--select-focus-box-shadow);
    border-color: var(--select-focus-border-color);
  }
}
</style>
