<template>
  <div
    class="playground p-5 bg-paper-2"
    :style="globalThemeVars">
    <div
      class="playground-container mx-auto bg-white br-1 border border-paper-3 shadow-1 p-5">
      <h1 class="typeset-2 mb-3">Other elements</h1>
      <p class="typeset-7 mb-4">
        Below are some elements that don't quite fit into the preview at the
        moment!
      </p>

      <p class="typeset-7 mb-2">Generic text</p>
      <p class="typeset-9 text-ink-3 mb-5">
        Shown in various parts of Guest ID and template
      </p>
      <p
        :style="computedStyle(theme, 'genericText')"
        class="generic-text mb-5">
        This is some generic text. It may have
        <a href="#">a link</a>
        inside it.
      </p>

      <p class="typeset-7 mb-2">A button</p>
      <p class="typeset-9 text-ink-3 mb-3">
        Appears on the Guest ID step and at the bottom of the page if there's a
        form to submit.
      </p>
      <btn
        type="secondary"
        data-element="button"
        class="btn-large mb-5"
        :theme="page.design.theme">
        {{ t("planner.pages.response.form.send-response") }}
      </btn>

      <p class="typeset-7 mb-2">RSVP button</p>
      <p class="typeset-9 text-ink-3 mb-3">
        You should be able to see the selected state if you click yes or no.
      </p>
      <div class="flex px-px mb-4">
        <button
          v-for="(choice, index) in choices"
          :key="index"
          class="btn btn-large rsvp-btn flex-1"
          :class="{ selected: isSelected(choice), '-mx-px': index === 0 }"
          :style="[theme.rsvpButton, selectedStyleProps(choice)]"
          data-element="rsvpButton"
          @click="selectedChoice = choice">
          {{ choice }}
        </button>
      </div>

      <p class="typeset-7 mb-2">An input with a label</p>
      <p class="typeset-9 text-ink-3 mb-3">Appears on the Guest ID step.</p>
      <div class="flex -mx-3 mb-5">
        <div class="px-3 w-1/2">
          <text-input
            required
            input-id="firstName"
            label="First name"
            class="mb-4"
            :theme="page.design.theme" />
        </div>
        <div class="px-3 w-1/2">
          <text-input
            required
            input-id="lastName"
            label="Last name"
            class="mb-4"
            :theme="page.design.theme" />
        </div>
      </div>

      <p class="typeset-7 mb-2">"Plus one" information</p>
      <p class="typeset-9 text-ink-3 mb-3">
        Shown when a guest has 1 or more plus ones.
      </p>
      <div
        class="plus-one-response-header -mx-5 mb-6 px-5 py-7"
        data-element="plusOneResponseHeader"
        :style="page.design.theme.plusOneResponseHeader">
        <p
          class="plus-one-response-heading"
          data-element="plusOneResponseHeading"
          :style="computedStyle(theme, 'plusOneResponseHeading')">
          Reply for your plus one
        </p>
        <p
          class="plus-one-response-subheading"
          data-element="plusOneResponseSubheading"
          :style="computedStyle(theme, 'plusOneResponseSubheading')">
          You’ve been assigned a plus one, we’ll need some answers for them too!
        </p>
      </div>
    </div>
    <slot name="highlighter"></slot>
  </div>
</template>

<script>
import Btn from "@/app/javascript/components/shared/Btn.vue";
import TextInput from "@/app/javascript/components/shared/TextInput.vue";
import computedStyle from "../../../mixins/computedStyle";

export default {
  name: "GlobalTemplate0",
  components: {
    Btn,
    TextInput
  },
  mixins: [computedStyle],
  props: {
    page: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      choices: ["yes", "no"],
      selectedChoice: null
    };
  },
  computed: {
    theme() {
      return this.page.design.theme;
    },
    globalThemeVars() {
      if (this.theme) {
        return {
          "--generic-link-color": this.theme.genericLink.color,
          "--generic-link-hover-color": this.theme.genericLink.hover.color,
          "--generic-link-text-decoration":
            this.theme.genericLink.textDecoration,
          "--generic-link-hover-text-decoration":
            this.theme.genericLink.hover.textDecoration
        };
      }
      return null;
    },
    isSelected() {
      return choice => {
        return this.selectedChoice === choice;
      };
    },
    selectedStyleProps() {
      return choice => {
        if (!this.isSelected(choice)) return null;
        return this.theme.rsvpButton.selected;
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.playground {
  min-height: 100vh;
}

.playground-container {
  max-width: 500px;
}

/*! purgecss start ignore */
// Styles for elements in child components
// that we want to control from this template
:deep(.hr) {
  width: 80px;
}

// The following styles pertain to the questions,
// which are inserted via the <slot /> in PageTemplateX.vue.
:deep(.question-label) {
  font-size: 16px;
  line-height: 1.75;
}

// Styles for the layout of the <select>
// element in this template.
:deep(.themed-select) {
  height: 46px;
  font-size: 14px;
  position: relative;
  background-color: transparent;
  padding-right: 32px;
  padding-left: 8px;
  background-image: url("@assets/images/svg/downarrow-small-thin.svg");
  background-repeat: no-repeat;
  background-size: 10px 6px;
  background-position: right 0.5rem center;

  // Base styles for the select - could be moved to a global file
  width: 100%;
  cursor: pointer;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  max-width: none;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

// Styles for the layout of the <text-input>
// component in this template.
:deep(.themed-text-input) {
  display: block;
  width: 100%;
  padding: 2px 10px 2px;
  height: 46px;
  font-size: 1rem;
  font-weight: normal;
  border-width: 1px;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

// Styles for the layout of the <textarea-input>
// component in this template.
:deep(.themed-textarea) {
  display: block;
  width: 100%;
  padding: 2px 10px 2px;
  font-size: 1rem;
  font-weight: normal;
  border-width: 1px;
  transition: background 0.1s ease;
}

// Styles for the layout of the <btn>
// component in this template.
:deep(.themed-button) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: normal;
  transition: background 0.1s ease;
}

// Styles for the layout of the rsvp buttons
// in this template.
:deep(.rsvp-btn) {
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  border-style: solid;

  .selected {
    z-index: 1;
  }
}

// Plus one section
:deep(.plus-one-response-header) {
  text-align: center;
}

:deep(.plus-one-response-heading) {
  font-size: 14px;
  line-height: 2;
  text-transform: uppercase;
}

:deep(.plus-one-response-subheading) {
  font-size: 13px;
  line-height: 1.4;
}

:deep(.responding-for-text) {
  font-size: 12px;
}

:deep(.not-person-link) {
  font-size: 12px;
  text-decoration: underline;
}

:deep(.generic-text) {
  font-size: 14px;
}

:deep(.generic-text) {
  a {
    color: var(--generic-link-color);
    text-decoration: var(--generic-link-text-decoration);

    &:hover {
      color: var(--generic-link-hover-color);
      text-decoration: var(--generic-link-hover-text-decoration);
    }
  }
}

:deep(.generic-link) {
  font-size: 14px;
  text-decoration: underline;
}
/*! purgecss end ignore */
</style>
